import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import config from '../../config';
import './CustomerAnalytics.scss';

const CustomerAnalytics = () => {
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartType, setChartType] = useState('line');

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.apiBaseUrl}/devices/analytics`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAnalytics(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch analytics');
        setLoading(false);
      }
    };
    fetchAnalytics();
  }, []);

  if (loading) return <div className="analytics-loading">Loading analytics...</div>;
  if (error) return <div className="analytics-error">{error}</div>;

  const chartData = [
    { name: 'Total', value: analytics.totalInspections },
    { name: 'Completed', value: analytics.completedInspections },
    { name: 'Pending', value: analytics.pendingInspections },
  ];

  const renderChart = () => {
    const ChartComponent = chartType === 'line' ? LineChart : BarChart;
    const DataComponent = chartType === 'line' ? Line : Bar;

    return (
      <ResponsiveContainer width="100%" height={300}>
        <ChartComponent data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <DataComponent type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" activeDot={{ r: 8 }} />
        </ChartComponent>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="analytics-container">
      <h2 className="analytics-title">Customer Analytics</h2>
      <div className="analytics-cards">
        <div className="analytics-card">
          <h3>Total Devices</h3>
          <p>{analytics.totalDevices}</p>
        </div>
        <div className="analytics-card">
          <h3>Total Inspections</h3>
          <p>{analytics.totalInspections}</p>
        </div>
        <div className="analytics-card">
          <h3>Completed Inspections</h3>
          <p>{analytics.completedInspections}</p>
        </div>
        <div className="analytics-card">
          <h3>Pending Inspections</h3>
          <p>{analytics.pendingInspections}</p>
        </div>
      </div>
      <div className="analytics-chart">
        <div className="chart-header">
          <h3>Inspection Overview</h3>
          <div className="chart-type-selector">
            <button
              className={`chart-type-btn ${chartType === 'line' ? 'active' : ''}`}
              onClick={() => setChartType('line')}
            >
              Line Chart
            </button>
            <button
              className={`chart-type-btn ${chartType === 'bar' ? 'active' : ''}`}
              onClick={() => setChartType('bar')}
            >
              Bar Chart
            </button>
          </div>
        </div>
        {renderChart()}
      </div>
    </div>
  );
};

export default CustomerAnalytics;