import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import { FaBell, FaUser, FaSignOutAlt, FaChartLine, FaTrash, FaCheck } from 'react-icons/fa';
import axios from 'axios';
import config from '../../config';
import './CustomerDashboard.scss';

const CustomerDashboard = ({ user, onLogout }) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/customer/customer-notifications`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const clearNotifications = async () => {
    try {
      await axios.delete(`${config.apiBaseUrl}/customer/customer-notifications`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setNotifications([]);
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      await axios.patch(`${config.apiBaseUrl}/customer/customer-notifications/${notificationId}`, 
        { read: true },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setNotifications(notifications.map(notification => 
        notification._id === notificationId ? { ...notification, read: true } : notification
      ));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <div className="cd-customer-dashboard">
      <header className="cd-dashboard-header">
        <div className="cd-logo" >
        <img style={{height: 100}} src="/finalLogo.png" alt="Admin Dashboard Logo" className="sidebar-logo" />
        </div>
        <div className="cd-user-actions">
          <button className="cd-icon-button" onClick={toggleNotifications}>
            <FaBell />
            {notifications.filter(n => !n.read).length > 0 && (
              <span className="cd-notification-badge">
                {notifications.filter(n => !n.read).length}
              </span>
            )}
          </button>
          <div className="cd-user-info" onClick={() => navigate('/customer-dashboard/profile')}>
            <FaUser />
            <span>{user.name}</span>
          </div>
          <button onClick={handleLogout} className="cd-btn-logout">
            <FaSignOutAlt />
            Logout
          </button>
        </div>
      </header>
      <div className="cd-dashboard-content">
        <nav className="cd-sidebar">
          <ul>
            <li><Link to="/customer-dashboard" className="cd-nav-link" end>My Devices</Link></li>
            <li><Link to="/customer-dashboard/devices/add" className="cd-nav-link">Add New Device</Link></li>
            <li>
              <Link to="/customer-dashboard/analytics" className="cd-nav-link">
                <FaChartLine />
                Analytics
              </Link>
            </li>
            <li>
              <Link to="/customer-dashboard/profile" className="cd-nav-link">
                <FaUser />
                Profile
              </Link>
            </li>
          </ul>
        </nav>
        <main className="cd-main-content">
          <Outlet />
        </main>
      </div>
      {showNotifications && (
        <div className="cd-notifications-panel">
          <div className="cd-notifications-header">
            <h3>Notifications</h3>
            <button onClick={clearNotifications} className="cd-clear-notifications">
              <FaTrash /> Clear All
            </button>
          </div>
          <div className="cd-notifications-list">
            {notifications.length > 0 ? (
              <ul>
                {notifications.map((notification) => (
                  <li key={notification._id} className={`cd-notification-item ${notification.read ? 'read' : ''}`}>
                    <p>{notification.message}</p>
                    {!notification.read && (
                      <button onClick={() => markNotificationAsRead(notification._id)} className="cd-mark-read">
                        <FaCheck />
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="cd-no-notifications">No new notifications</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDashboard;