import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './DeviceManagement.scss';
import config from '../../config';
import CustomerDeviceReport from './CustomerDeviceReport';

const DeviceDetails = () => {
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [latestInspection, setLatestInspection] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem('token');
        
        if (!id) {
          throw new Error('Device ID is missing');
        }

        const deviceResponse = await axios.get(`${config.apiBaseUrl}/devices/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        setDevice(deviceResponse.data.data);

        try {
          const inspectionsResponse = await axios.get(`${config.apiBaseUrl}/customer/device-inspections/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          if (inspectionsResponse.data.length > 0) {
            setLatestInspection(inspectionsResponse.data[0]);
          }
        } catch (inspectionError) {
          console.error('Error fetching inspections:', inspectionError);
          // We don't set the main error state here, as we still want to display the device details
        }

      } catch (err) {
        console.error('Error fetching device details:', err);
        setError(err.response?.data?.message || 'Failed to fetch device details');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this device?')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${config.apiBaseUrl}/devices/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        navigate('/customer-dashboard');
      } catch (err) {
        setError('Failed to delete device');
      }
    }
  };

  const toggleReport = () => {
    setShowReport(!showReport);
  };

  if (loading) return <div className="loading">Loading device details...</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!device) return <div className="error-message">Device not found</div>;

  return (
    <div className="iot-device-details">
      <h2>{device.type} Details</h2>
      <div className="iot-device-info">
        <p><strong>Serial Number:</strong> {device.serialNumber}</p>
        <p><strong>Location:</strong> {device.location}</p>
        <p><strong>Installation Date:</strong> {new Date(device.installationDate).toLocaleDateString()}</p>
        <p><strong>Last Inspection Date:</strong> {device.lastInspectionDate ? new Date(device.lastInspectionDate).toLocaleDateString() : 'N/A'}</p>
        <p><strong>Next Inspection Date:</strong> {device.nextInspectionDate ? new Date(device.nextInspectionDate).toLocaleDateString() : 'N/A'}</p>
        <p><strong>Status:</strong> <span className={`iot-status iot-${device.status.toLowerCase()}`}>{device.status}</span></p>
      </div>
      {device.qrCode && (
        <div className="iot-qr-code-container">
          <h3>Device QR Code</h3>
          <img src={device.qrCode} alt="Device QR Code" />
          <p>Scan this QR code to quickly access device information.</p>
        </div>
      )}
      <div className="iot-device-actions">
        {latestInspection && latestInspection.status === 'Completed' && (
          <button className="iot-btn-primary" onClick={toggleReport}>View Latest Inspection Report</button>
        )}
        <button className="iot-btn-danger" onClick={handleDelete}>Delete Device</button>
      </div>
      {showReport && latestInspection && (
        <CustomerDeviceReport
          deviceId={id}
          inspectionId={latestInspection._id}
          onClose={toggleReport}
        />
      )}
    </div>
  );
};

export default DeviceDetails;