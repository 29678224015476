import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faFilePdf, faSpinner } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import './DeviceManagement.scss';
import jsPDF from 'jspdf';

const DeviceManagement = () => {
  const [devices, setDevices] = useState([]);
  const [managers, setManagers] = useState([]);
  const [error, setError] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedManager, setSelectedManager] = useState('');
  const [scheduledDate, setScheduledDate] = useState('');
  const [generatingPDF, setGeneratingPDF] = useState(false);

  useEffect(() => {
    fetchAllDevices();
    fetchManagers();
  }, []);

  const fetchAllDevices = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiBaseUrl}/devices/all`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setDevices(response.data);
    } catch (err) {
      setError('Failed to fetch devices');
      console.error('Error fetching devices:', err);
    }
  };

  const fetchManagers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiBaseUrl}/inspections/managers`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setManagers(response.data);
    } catch (err) {
      setError('Failed to fetch managers');
      console.error('Error fetching managers:', err);
    }
  };
  
  const assignInspection = async () => {
    if (!selectedDevice || !selectedManager || !scheduledDate) {
      setError('Please select a device, a manager, and a scheduled date');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${config.apiBaseUrl}/inspections/assign`,
        {
          deviceId: selectedDevice._id,
          managerId: selectedManager,
          scheduledDate: scheduledDate
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setError(null);
      alert('Inspection assigned successfully');
      fetchAllDevices(); // Refresh the device list
      setSelectedDevice(null);
      setSelectedManager('');
      setScheduledDate('');
    } catch (err) {
      setError('Failed to assign inspection');
      console.error('Error assigning inspection:', err);
    }
  };

  return (
    <div className="device-management">
      <h2>Device Management</h2>
      {error && <div className="error-message">{error}</div>}
      <table>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Type</th>
            <th>Location</th>
            <th>Installation Date</th>
            <th>Status</th>
            <th>Customer Name</th>
            <th>Customer Email</th>
            <th>QR Code</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {devices.map(device => (
            <tr key={device._id}>
              <td>{device.serialNumber}</td>
              <td>{device.type}</td>
              <td>{device.location}</td>
              <td>{new Date(device.installationDate).toLocaleDateString()}</td>
              <td>{device.status}</td>
              <td>{device.customer.name}</td>
              <td>{device.customer.email}</td>
              <td>
                {device.qrCode && (
                  <img 
                    src={device.qrCode} 
                    alt={`QR Code for ${device.serialNumber}`} 
                    className="device-qr-code"
                  />
                )}
              </td>
              <td>
                <button onClick={() => setSelectedDevice(device)}>
                  Assign Inspection
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedDevice && (
        <div className="assign-inspection">
          <h3>Assign Inspection for Device: {selectedDevice.serialNumber}</h3>
          <select
            value={selectedManager}
            onChange={(e) => setSelectedManager(e.target.value)}
          >
            <option value="">Select a Manager</option>
            {managers.map(manager => (
              <option key={manager._id} value={manager._id}>
                {manager.name}
              </option>
            ))}
          </select>
          <input
            type="date"
            value={scheduledDate}
            onChange={(e) => setScheduledDate(e.target.value)}
          />
          <button onClick={assignInspection}>Assign Inspection</button>
        </div>
      )}
    </div>
  );
};

export default DeviceManagement;