import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './DeviceManagement.scss';
import config from '../../config';

const AddDevice = () => {
  const [device, setDevice] = useState({
    serialNumber: '',
    type: '',
    location: '',
    installationDate: ''
  });
  const [error, setError] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const navigate = useNavigate();
  
  const handleChange = (e) => {
    setDevice({ ...device, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${config.apiBaseUrl}/devices`, device, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setQrCode(response.data.data.qrCode);
    } catch (err) {
      setError('Failed to add device');
      console.error('Error adding device:', err);
    }
  };

  const handleDone = () => {
    navigate('/customer-dashboard');
  };

  return (
<div className="iot-add-device">
<h2>Add New Device</h2>
{error && <div className="iot-error-message">{error}</div>}
{!qrCode ? (
  <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="serialNumber">Serial Number</label>
          <input
            type="text"
            id="serialNumber"
            name="serialNumber"
            value={device.serialNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type</label>
          <select
            id="type"
            name="type"
            value={device.type}
            onChange={handleChange}
            required
          >
            <option value="">Select Type</option>
            <option value="Fire Extinguisher">Fire Extinguisher</option>
            <option value="AED">AED</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="location">Location</label>
          <input
            type="text"
            id="location"
            name="location"
            value={device.location}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="installationDate">Installation Date</label>
          <input
            type="date"
            id="installationDate"
            name="installationDate"
            value={device.installationDate}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="iot-btn-primary">Add Device</button>
        </form>
      ) : (
        <div className="iot-qr-code-container">
          <h3>Device Added Successfully</h3>
          <p>Scan this QR code to access device information:</p>
          <img src={qrCode} alt="Device QR Code" />
          <button onClick={handleDone} className="iot-btn-primary">Done</button>
        </div>
      )}
    </div>
  );
};

export default AddDevice;