import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTimes, faFileDownload, faClipboardCheck, faExclamationTriangle 
} from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import './CustomerReport.scss';

const CustomerReport = ({ deviceId, inspectionId, onClose }) => {
  const [inspection, setInspection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchInspectionDetails();
  }, [deviceId, inspectionId]);

  const fetchInspectionDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiBaseUrl}/customer/device-report/${deviceId}/${inspectionId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setInspection(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch inspection details');
      setLoading(false);
      console.error('Error fetching inspection details:', err);
    }
  };

  const handleDocumentDownload = async (documentId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiBaseUrl}/customer/document/${inspectionId}/${documentId}`, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const contentType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'document.pdf';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
        if (filenameMatch && filenameMatch.length === 2)
          filename = filenameMatch[1];
      }
      
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading document:', error);
      setError('Failed to download document: ' + error.message);
    }
  };

  if (loading) return <div className="loading">Loading inspection report...</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!inspection) return null;

  return (
    <div className="customer-report-modal">
      <div className="report-content">
        <h3>Inspection Report</h3>
        <button onClick={onClose} className="close-btn"><FontAwesomeIcon icon={faTimes} /></button>
        <div className="report-details">
          <p><strong>Device Serial:</strong> {inspection.device.serialNumber}</p>
          <p><strong>Inspector:</strong> {inspection.inspector.name}</p>
          <p><strong>Completed Date:</strong> {new Date(inspection.completedDate).toLocaleString()}</p>
          <p><strong>Result:</strong> 
            <span className={`status-badge ${inspection.result.toLowerCase()}`}>
              {inspection.result === 'Pass' ? (
                <FontAwesomeIcon icon={faClipboardCheck} />
              ) : (
                <FontAwesomeIcon icon={faExclamationTriangle} />
              )}
              {inspection.result}
            </span>
          </p>
          <p><strong>Comments:</strong> {inspection.comments}</p>
        </div>
        <div className="checklist">
          <h4>Checklist</h4>
          <ul>
            {inspection.checklist.map((item, index) => (
              <li key={index} className={item.checked ? 'checked' : 'unchecked'}>
                <FontAwesomeIcon icon={item.checked ? faClipboardCheck : faExclamationTriangle} />
                {item.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="photos">
          <h4>Photos</h4>
          <div className="photo-gallery">
            {inspection.photos.map((photo, index) => (
              <img 
                key={index} 
                src={`${config.apiBaseUrl}/customer/image/${photo.filename}`} 
                alt={`Inspection photo ${index + 1}`} 
              />
            ))}
          </div>
        </div>
        <div className="documents">
          <h4>Documents</h4>
          <ul>
            {inspection.documents.map((doc) => (
              <li key={doc._id}>
                <a href="#" onClick={(e) => { e.preventDefault(); handleDocumentDownload(doc._id); }} className="document-download-link">
                  <FontAwesomeIcon icon={faFileDownload} /> {doc.originalName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustomerReport;