import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './DeviceManagement.scss';
import config from '../../config';

const DeviceList = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDevices = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${config.apiBaseUrl}/devices`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setDevices(response.data);
          setLoading(false);
        } catch (err) {
          console.error('Error fetching devices:', err); 
          setError('Failed to fetch devices');
          setLoading(false);
        }
      };
    fetchDevices();
  }, []);

  if (loading) return <div className="loading">Loading devices...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
<div className="iot-device-list">
  <h2>Your Devices</h2>
  <Link to="/customer-dashboard/devices/add" className="iot-btn-primary">Add New Device</Link>
  <div className="iot-device-grid">
    {devices.map(device => (
      <div key={device._id} className="iot-device-card">
        <h3 className="iot-device-name">{device.type}</h3>
        <p className="iot-device-info">Serial Number: {device.serialNumber}</p>
        <p className="iot-device-info">Location: {device.location}</p>
        <p>Status: <span className={`iot-status iot-${device.status.toLowerCase()}`}>{device.status}</span></p>
        <Link to={`/customer-dashboard/devices/${device._id}`} className="iot-btn-secondary">View Details</Link>
      </div>
    ))}
  </div>
</div>

  );
};

export default DeviceList;