import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import CustomerDashboard from './components/CustomerDashboard/CustomerDashboard';
import DeviceList from './components/CustomerDashboard/DeviceList';
import AddDevice from './components/CustomerDashboard/AddDevice';
import DeviceDetails from './components/CustomerDashboard/DeviceDetails';
import AdminDashboard from './components/Admin/AdminDashboard';
import UserManagement from './components/Admin/UserManagement';
import DeviceManagement from './components/Admin/DeviceManagement';
import ManagerDashboard from './components/inspectors/ManagerDashboard';
import CustomerAnalytics from './components/CustomerDashboard/CustomerAnalytics';
import Profile from './components/CustomerDashboard/Profile';

import './styles/main.scss';
import config from './config';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userRole = localStorage.getItem('userRole');
    if (token && userRole) {
      setUser({ token, role: userRole });
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  const handleLogin = (token, role) => {
    localStorage.setItem('token', token);
    localStorage.setItem('userRole', role);
    setUser({ token, role });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    setUser(null);
  };

  const getDefaultRoute = (user) => {
    if (!user) return "/login";
    switch (user.role) {
      case 'admin':
        return "/admin-dashboard";
      case 'inspection_manager':
        return "/manager-dashboard";
      default:
        return "/customer-dashboard";
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/login" element={user ? <Navigate to={getDefaultRoute(user)} /> : <Login onLogin={handleLogin} />} />
          <Route path="/register" element={user ? <Navigate to={getDefaultRoute(user)} /> : <Register />} />

          {/* Customer routes */}
          <Route
            path="/customer-dashboard/*"
            element={user && user.role === 'customer' ? 
              <CustomerDashboard user={user} onLogout={handleLogout} /> : 
              <Navigate to="/login" />
            }
          >
            <Route index element={<DeviceList user={user} />} />
            <Route path="devices/add" element={<AddDevice user={user} />} />
            <Route path="devices/:id" element={<DeviceDetails user={user} />} />
            <Route path="analytics" element={<CustomerAnalytics user={user} />} />
            <Route path="profile" element={<Profile />} />
          </Route>

          {/* Admin routes */}
          <Route
            path="/admin-dashboard/*"
            element={user && user.role === 'admin' ? <AdminDashboard onLogout={handleLogout} /> : <Navigate to="/login" />}
          >
            <Route index element={<AdminDashboard />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="devices" element={<DeviceManagement />} />
          </Route>

          {/* Manager routes */}
          <Route 
            path="/manager-dashboard/*" 
            element={user && user.role === 'inspection_manager' ? <ManagerDashboard onLogout={handleLogout} /> : <Navigate to="/login" />} 
          >
            <Route index element={<ManagerDashboard />} />
          </Route>

          {/* Default route */}
          <Route path="/" element={<Navigate to={getDefaultRoute(user)} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;