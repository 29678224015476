import React, { useState, useEffect } from 'react';
import './InspectionForm.scss';
import axios from 'axios';

const InspectionForm = ({ inspection, onComplete, onCancel }) => {
  const [formData, setFormData] = useState({
    checklist: {},
    comments: '',
    result: '',
    photos: [],
    documents: []
  });
  const [dynamicFields, setDynamicFields] = useState([]);
  const [error, setError] = useState(null);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [photoPreviewUrls, setPhotoPreviewUrls] = useState([]);

  useEffect(() => {
    // Initialize dynamic fields based on the device type
    if (inspection.device.type === 'Fire Extinguisher') {
      setDynamicFields([
        { id: 1, name: 'Pressure gauge in operating range', type: 'checkbox' },
        { id: 2, name: 'No visible damage', type: 'checkbox' },
        { id: 3, name: 'Safety pin in place', type: 'checkbox' },
        { id: 4, name: 'Nozzle clear of obstructions', type: 'checkbox' }
      ]);
    } else if (inspection.device.type === 'AED') {
      setDynamicFields([
        { id: 1, name: 'Battery indicator shows full charge', type: 'checkbox' },
        { id: 2, name: 'Pads are sealed and undamaged', type: 'checkbox' },
        { id: 3, name: 'Device self-test passes', type: 'checkbox' },
        { id: 4, name: 'Visible indicators functioning', type: 'checkbox' }
      ]);
    }
  }, [inspection.device.type]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleDynamicFieldChange = (id, value) => {
    setFormData(prevState => ({
      ...prevState,
      checklist: {
        ...prevState.checklist,
        [id]: value
      }
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'photos') {
      setPhotoFiles([...photoFiles, ...files]);
      const newPhotoUrls = Array.from(files).map(file => URL.createObjectURL(file));
      setPhotoPreviewUrls(prevUrls => [...prevUrls, ...newPhotoUrls]);
    } else if (name === 'documents') {
      setFormData(prevState => ({
        ...prevState,
        documents: [...prevState.documents, ...files]
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.result) {
      setError('Please select a result before submitting.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('result', formData.result);
    formDataToSend.append('comments', formData.comments);

    // Include checklist items and their content
    const checklistWithContent = dynamicFields.map(field => ({
      id: field.id,
      name: field.name,
      checked: formData.checklist[field.id] || false
    }));
    formDataToSend.append('checklist', JSON.stringify(checklistWithContent));

    photoFiles.forEach(file => {
      formDataToSend.append('photos', file);
    });

    formData.documents.forEach(file => {
      formDataToSend.append('documents', file);
    });

    try {
      const response = await axios.post(`/api/manager/complete-inspection/${inspection._id}`, formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      onComplete(response.data);
    } catch (error) {
      setError('Failed to complete inspection: ' + error.response?.data?.message || error.message);
    }
  };

  const addDynamicField = () => {
    const newId = dynamicFields.length + 1;
    setDynamicFields([...dynamicFields, { id: newId, name: '', type: 'checkbox' }]);
  };

  const updateDynamicField = (id, name, type) => {
    setDynamicFields(dynamicFields.map(field => 
      field.id === id ? { ...field, name, type } : field
    ));
  };

  const deleteDynamicField = (id) => {
    setDynamicFields(dynamicFields.filter(field => field.id !== id));
    setFormData(prevState => {
      const newChecklist = { ...prevState.checklist };
      delete newChecklist[id];
      return { ...prevState, checklist: newChecklist };
    });
  };

  return (
    <form onSubmit={handleSubmit} className="inspection-form">
      <h2>Inspection for {inspection.device.serialNumber}</h2>
      {error && <div className="error-message">{error}</div>}
      
      <div className="form-section checklist-section">
        <h3>Checklist</h3>
        {dynamicFields.map(field => (
          <div key={field.id} className="checklist-item">
            <input
              type={field.type}
              id={`checklist-${field.id}`}
              checked={formData.checklist[field.id] || false}
              onChange={(e) => handleDynamicFieldChange(field.id, e.target.checked)}
            />
            <input
              type="text"
              value={field.name}
              onChange={(e) => updateDynamicField(field.id, e.target.value, field.type)}
              className="checklist-item-name"
            />
            <button type="button" onClick={() => deleteDynamicField(field.id)} className="delete-field">Delete</button>
          </div>
        ))}
        <button type="button" onClick={addDynamicField} className="add-field">Add Field</button>
      </div>

      <div className="form-section comments-section">
        <label htmlFor="comments">Comments:</label>
        <textarea
          id="comments"
          name="comments"
          value={formData.comments}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-section result-section">
        <label htmlFor="result">Result:</label>
        <select
          id="result"
          name="result"
          value={formData.result}
          onChange={handleInputChange}
          required
        >
          <option value="">Select a result</option>
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
          <option value="Maintenance Needed">Maintenance Needed</option>
        </select>
      </div>

      <div className="form-section file-upload-section">
        <div className="photo-upload">
          <label htmlFor="photos">Upload Photos:</label>
          <input
            type="file"
            id="photos"
            name="photos"
            multiple
            onChange={handleFileChange}
            accept="image/*"
          />
          <div className="photo-preview">
            {photoPreviewUrls.map((url, index) => (
              <img key={index} src={url} alt={`Preview ${index + 1}`} />
            ))}
          </div>
        </div>
        <div className="document-upload">
          <label htmlFor="documents">Upload Documents:</label>
          <input
            type="file"
            id="documents"
            name="documents"
            multiple
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx"
          />
          <div className="document-list">
            {formData.documents.map((doc, index) => (
              <p key={index}>{doc.name}</p>
            ))}
          </div>
        </div>
      </div>

      <div className="form-actions">
        <button type="submit" className="complete-btn">Complete Inspection</button>
        <button type="button" onClick={onCancel} className="cancel-btn">Cancel</button>
      </div>
    </form>
  );
};

export default InspectionForm;