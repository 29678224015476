import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import './Auth.scss';

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(`${config.apiBaseUrl}/auth/login`, formData);
      const token = res.data.token;
      const role = res.data.role;

      localStorage.setItem('token', token);
      localStorage.setItem('userRole', role);

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      onLogin(token, role);
      switch (role) {
        case 'admin':
          navigate('/admin-dashboard');
          break;
        case 'inspection_manager':
          navigate('/manager-dashboard');
          break;
        case 'customer':
        default:
          navigate('/customer-dashboard');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred during login');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="auth-header">
          <img src="/finalLogo.png" alt="Logo" className="auth-header__logo" />
          <h2 className="auth-header__title">Welcome Back</h2>
          <p className="auth-header__subtitle">Please sign in to your account</p>
        </div>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={onSubmit} className="auth-form">
          <div className="auth-form__group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              required
              placeholder="Enter your email"
            />
          </div>
          <div className="auth-form__group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              required
              placeholder="Enter your password"
            />
          </div>
          <button type="submit" className="auth-form__button" disabled={isLoading}>
            {isLoading ? 'Signing In...' : 'Sign In'}
          </button>
        </form>
        <p className="auth-switch">
          Don't have an account? <Link to="/register">Sign Up</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;