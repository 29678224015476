import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import InspectionForm from './InspectionForm';
import InspectionReport from './InspectionReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClipboardCheck, faClipboardList, faTachometerAlt, faSignOutAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import './ManagerDashboard.scss';


const ManagerDashboard = ({ onLogout }) => {
  const [notifications, setNotifications] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [error, setError] = useState(null);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [activeTab, setActiveTab] = useState('incoming');
  const [inspectionDetails, setInspectionDetails] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [analytics, setAnalytics] = useState({ completed: 0, assigned: 0 });
  const navigate = useNavigate();
  
  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    delete axios.defaults.headers.common['Authorization'];
    
    // Call the onLogout prop function
    onLogout();
    
    // Navigate to the login page
    navigate('/login');
  };
  useEffect(() => {
    fetchNotifications();
    fetchInspections();
    fetchAnalytics();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/manager/notifications`);
      setNotifications(response.data);
    } catch (err) {
      setError('Failed to fetch notifications');
      console.error('Error fetching notifications:', err);
    }
  };

  const fetchInspections = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/manager/inspections`);
      setInspections(response.data);
    } catch (err) {
      setError('Failed to fetch inspections');
      console.error('Error fetching inspections:', err);
    }
  };

  const fetchAnalytics = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/manager/analytics`);
      setAnalytics(response.data);
    } catch (err) {
      setError('Failed to fetch analytics');
      console.error('Error fetching analytics:', err);
    }
  };

  const clearNotifications = async () => {
    try {
      await axios.delete(`${config.apiBaseUrl}/manager/notifications`);
      setNotifications([]);
    } catch (err) {
      setError('Failed to clear notifications');
      console.error('Error clearing notifications:', err);
    }
  };

  const startInspection = async (inspectionId) => {
    try {
      const response = await axios.post(`${config.apiBaseUrl}/manager/start-inspection/${inspectionId}`);
      setSelectedInspection(response.data);
      setInspections(prevInspections => 
        prevInspections.map(inspection => 
          inspection._id === inspectionId 
            ? { ...inspection, status: 'In Progress' } 
            : inspection
        )
      );
    } catch (err) {
      setError('Failed to start inspection');
      console.error('Error starting inspection:', err);
    }
  };

  const completeInspection = async (inspectionData) => {
    try {
      const formData = new FormData();
      
      Object.keys(inspectionData).forEach(key => {
        if (key === 'photos' || key === 'documents') {
          inspectionData[key].forEach(file => {
            formData.append(key, file);
          });
        } else if (key === 'checklist') {
          formData.append(key, JSON.stringify(inspectionData[key]));
        } else {
          formData.append(key, inspectionData[key]);
        }
      });

      const response = await axios.post(
        `${config.apiBaseUrl}/manager/complete-inspection/${selectedInspection._id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      setSelectedInspection(null);
      setInspections(prevInspections => 
        prevInspections.map(inspection => 
          inspection._id === selectedInspection._id 
            ? response.data.inspection 
            : inspection
        )
      );
      setError(null);
    } catch (err) {
      setError('Failed to complete inspection: ' + (err.response?.data?.message || err.message));
      console.error('Error completing inspection:', err);
    }
  };

  const cancelInspection = () => {
    setInspections(prevInspections => 
      prevInspections.map(inspection => 
        inspection._id === selectedInspection._id 
          ? { ...inspection, status: 'Cancelled' } 
          : inspection
      )
    );
    setSelectedInspection(null);
  };

  const viewInspectionReport = async (inspectionId) => {
    try {
      setInspectionDetails(inspectionId);
      setShowReport(true);
    } catch (err) {
      setError('Failed to fetch inspection details');
      console.error('Error fetching inspection details:', err);
    }
  };

  const isInspectionScheduledForToday = (scheduledDate) => {
    const today = new Date();
    const scheduled = new Date(scheduledDate);
    return today.toDateString() === scheduled.toDateString();
  };
  return (
    <div className="elegant-dashboard">
      <aside className="elegant-sidebar">
        <div className="elegant-logo">        <img src="/finalLogo.png" alt="Admin Dashboard Logo" className="sidebar-logo" />
        </div>
        <nav className="navigation">
      <ul className="tab-list">
        <li
          className={`tab ${activeTab === 'incoming' ? 'active' : ''}`}
          onClick={() => setActiveTab('incoming')}
          aria-label="Go to incoming tab"
        >
          <FontAwesomeIcon icon={faClipboardList} />
          <span>Incoming</span>
        </li>
        <li
          className={`tab ${activeTab === 'completed' ? 'active' : ''}`}
          onClick={() => setActiveTab('completed')}
          aria-label="Go to completed tab"
        >
          <FontAwesomeIcon icon={faClipboardCheck} />
          <span>Completed</span>
        </li>
        <li
          className={`tab ${activeTab === 'dashboard' ? 'active' : ''}`}
          onClick={() => setActiveTab('dashboard')}
          aria-label="Go to dashboard tab"
        >
          <FontAwesomeIcon icon={faTachometerAlt} />
          <span>Dashboard</span>
        </li>
      </ul>
    </nav>
    <div className="elegant-logout" onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          <span>Logout</span>
        </div>
      </aside>
      <main className="elegant-content">
        {error && <div className="elegant-error-message">{error}</div>}
        
        <div className="elegant-header">
          <h1>{activeTab === 'incoming' ? 'Incoming Inspections' : activeTab === 'completed' ? 'Completed Inspections' : 'Dashboard'}</h1>
          <div className="elegant-notifications-icon" onClick={() => setShowNotifications(!showNotifications)}>
            <FontAwesomeIcon icon={faBell} />
            {notifications.length > 0 && <span className="elegant-notification-badge">{notifications.length}</span>}
          </div>
        </div>
  
        {showNotifications && (
          <div className="elegant-notifications-dropdown">
            <h3>Notifications</h3>
            {notifications.length === 0 ? (
              <p>No new notifications</p>
            ) : (
              <>
                {notifications.map(notification => (
                  <div key={notification._id} className="elegant-notification">
                    <p>{notification.message}</p>
                    <small>{new Date(notification.createdAt).toLocaleString()}</small>
                  </div>
                ))}
                <button className="elegant-clear-notifications" onClick={clearNotifications}>
                  <FontAwesomeIcon icon={faTrash} /> Clear All
                </button>
              </>
            )}
          </div>
        )}
  
        {activeTab === 'dashboard' ? (
          <div className="elegant-analytics">
            <h2>Analytics</h2>
            <div className="analytics-cards">
              <div className="analytics-card">
                <h3>Completed Inspections</h3>
                <p>{analytics.completed}</p>
              </div>
              <div className="analytics-card">
                <h3>Assigned Inspections</h3>
                <p>{analytics.assigned}</p>
              </div>
            </div>
          </div>
        ) : selectedInspection ? (
          <InspectionForm 
            inspection={selectedInspection} 
            onComplete={completeInspection} 
            onCancel={cancelInspection}
          />
        ) : showReport ? (
          <InspectionReport 
            inspectionId={inspectionDetails}
            onClose={() => setShowReport(false)}
          />
        ) : (
          <div className="elegant-inspections-section">
            {inspections.length === 0 ? (
              <p>No {activeTab === 'incoming' ? 'incoming' : 'completed'} inspections</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>Device Serial</th>
                    <th>Location</th>
                    <th>Scheduled Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
      {inspections
        .filter(inspection => activeTab === 'incoming' ? inspection.status !== 'Completed' && inspection.status !== 'Cancelled' : inspection.status === 'Completed')
        .map(inspection => (
        <tr key={inspection._id}>
          <td>{inspection.device.serialNumber}</td>
          <td>{inspection.device.location}</td>
          <td>{new Date(inspection.scheduledDate).toLocaleDateString()}</td>
          <td>{inspection.status}</td>
          <td>
            {inspection.status === 'Scheduled' && (
              <button 
                onClick={() => startInspection(inspection._id)}
                disabled={!isInspectionScheduledForToday(inspection.scheduledDate)}
              >
                Start Inspection
              </button>
            )}
            {inspection.status === 'Completed' && (
              <button onClick={() => viewInspectionReport(inspection._id)}>
                View Report
              </button>
            )}
          </td>
        </tr>
      ))}
    </tbody>
              </table>
            )}
          </div>
        )}
      </main>
    </div>
  );
};
export default ManagerDashboard;