import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faClipboardCheck, faUsers, faMicrochip, faFileAlt, faTimes, faFileDownload, 
  faTachometerAlt, faCog, faSignOutAlt, faBell, faChevronLeft, faChevronRight,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import config from '../../config';
import './AdminDashboard.scss';
import DeviceManagement from './DeviceManagement'; 
import UserManagement from './UserManagement';
import jsPDF from 'jspdf';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = ({ onLogout }) => {
  const [dashboardStats, setDashboardStats] = useState({
    users: { totalUsers: 0, customerCount: 0, inspectorCount: 0 },
    devices: { totalDevices: 0, deviceTypes: [] },
    inspections: { totalInspections: 0, completedInspections: 0, pendingInspections: 0 }
  });
  const [recentInspections, setRecentInspections] = useState([]);
  const [error, setError] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [showReport, setShowReport] = useState(false);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const navigate = useNavigate();



  useEffect(() => {
    fetchDashboardStats();
    fetchRecentInspections();
  }, []);

  const fetchDashboardStats = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/admin/dashboard-stats`);
      setDashboardStats(response.data);
    } catch (err) {
      setError('Failed to fetch dashboard statistics');
      console.error('Error fetching dashboard statistics:', err);
    }
  };
  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    delete axios.defaults.headers.common['Authorization'];
    
    // Call the onLogout prop function
    onLogout();
    
    // Navigate to the login page
    navigate('/login');
  };
  const fetchRecentInspections = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/admin/recent-completed-inspections`);
      setRecentInspections(response.data);
    } catch (err) {
      setError('Failed to fetch recent inspections');
      console.error('Error fetching recent inspections:', err);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleViewReport = (inspection) => {
    setSelectedInspection(inspection);
    setShowReport(true);
  };

  const handleCloseReport = () => {
    setShowReport(false);
    setSelectedInspection(null);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return (
          <div className="dashboard-grid">
            <div className="dashboard-cards">
              <DashboardCard icon={faUsers} title="Total Users" value={dashboardStats.users.totalUsers} />
              <DashboardCard icon={faMicrochip} title="Active Devices" value={dashboardStats.devices.totalDevices} />
              <DashboardCard icon={faClipboardCheck} title="Completed Inspections" value={dashboardStats.inspections.completedInspections} />
            </div>
            <RecentInspections inspections={recentInspections} error={error} onViewReport={handleViewReport} />
          </div>
        );
      case 'devices':
        return <DeviceManagement />;
      case 'users':
        return <UserManagement />;
      default:
        return null;
    }
  };
  return (
    <div className={`admin-dashboard ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Sidebar 
        isOpen={sidebarOpen} 
        toggleSidebar={toggleSidebar} 
        activeTab={activeTab} 
        setActiveTab={setActiveTab}
        onLogout={handleLogout}  // Pass the handleLogout function here
      />
      <main className="dashboard-content">
        <TopBar />
        {showReport ? (
          <ReportComponent inspection={selectedInspection} onClose={handleCloseReport} />
        ) : (
          renderContent()
        )}
      </main>
    </div>
  );
};

const TopBar = () => {
  return (
    <header className="dashboard-header">
      <h1>Admin Dashboard</h1>
      <div className="header-actions">
        
        <img src="/icon.png" alt="Logo" className="top-bar-logo" />
      </div>
    </header>
  );
};

const Sidebar = ({ isOpen, toggleSidebar, activeTab, setActiveTab, onLogout }) => {
 
  return (
    <aside className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
        <img src="/finalLogo.png" alt="Admin Dashboard Logo" className="sidebar-logo" />
        <button onClick={toggleSidebar} className="toggle-btn">
          {!isOpen && (
            <>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className="arrow-text">Open Sidebar</span>
            </>
          )}
        </button>
      </div>
      <nav className="sidebar-nav">
        <a className={`nav-item ${activeTab === 'dashboard' ? 'active' : ''}`} onClick={() => setActiveTab('dashboard')}>
          <FontAwesomeIcon icon={faTachometerAlt} />
          <span>Dashboard</span>
        </a>
        <a className={`nav-item ${activeTab === 'devices' ? 'active' : ''}`} onClick={() => setActiveTab('devices')}>
          <FontAwesomeIcon icon={faMicrochip} />
          <span>Devices</span>
        </a>
        <a className={`nav-item ${activeTab === 'users' ? 'active' : ''}`} onClick={() => setActiveTab('users')}>
          <FontAwesomeIcon icon={faUsers} />
          <span>Users</span>
        </a>
        
        <a className="nav-item logout-btn" onClick={onLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          <span>Logout</span>
        </a>
      </nav>
    </aside>
  );
};

const DashboardCard = ({ icon, title, value }) => {
  return (
    <div className="dashboard-card">
      <FontAwesomeIcon icon={icon} className="card-icon" />
      <div className="card-content">
        <h3>{title}</h3>
        <p>{value}</p>
      </div>
    </div>
  );
};

const CompletedInspections = ({ inspections, error }) => {
  const [selectedInspection, setSelectedInspection] = useState(null);

  const handleViewReport = (inspection) => {
    setSelectedInspection(inspection);
  };

  const handleCloseReport = () => {
    setSelectedInspection(null);
  };

  return (
    <div className="completed-inspections">
      <h3><FontAwesomeIcon icon={faClipboardCheck} /> Completed Inspections</h3>
      {error && <div className="error-message">{error}</div>}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Device Serial</th>
              <th>Inspector</th>
              <th>Completed Date</th>
              <th>Result</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {inspections.map(inspection => (
              <tr key={inspection._id}>
                <td>{inspection.device.serialNumber}</td>
                <td>{inspection.inspector.name}</td>
                <td>{new Date(inspection.completedDate).toLocaleString()}</td>
                <td>
                  <span className={`status-badge ${inspection.result.toLowerCase()}`}>
                    {inspection.result}
                  </span>
                </td>
                <td>
                  <button onClick={() => handleViewReport(inspection)} className="view-report-btn">
                    <FontAwesomeIcon icon={faFileAlt} /> View Report
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
     
    </div>
  );
};
const RecentInspections = ({ inspections, error, onViewReport }) => {
  return (
    <div className="recent-inspections">
      <h3><FontAwesomeIcon icon={faClipboardCheck} /> Recent Completed Inspections</h3>
      {error && <div className="error-message">{error}</div>}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Device Serial</th>
              <th>Inspector</th>
              <th>Completed Date</th>
              <th>Result</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {inspections.map((inspection) => (
              <tr key={inspection._id}>
                <td>{inspection.deviceInfo[0].serialNumber}</td>
                <td>{inspection.inspectorInfo[0].name}</td>
                <td>{new Date(inspection.completedDate).toLocaleString()}</td>
                <td>
                  <span className={`status-badge ${inspection.result.toLowerCase()}`}>
                    {inspection.result}
                  </span>
                </td>
                <td>
                  <button onClick={() => onViewReport(inspection)} className="view-report-btn">
                    <FontAwesomeIcon icon={faFileAlt} /> View Report
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ReportComponent = ({ inspection, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inspectionDetails, setInspectionDetails] = useState(null);
  const [generatingPDF, setGeneratingPDF] = useState(false);

  useEffect(() => {
    fetchInspectionDetails();
  }, [inspection]);

  const fetchInspectionDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiBaseUrl}/admin/inspection-details/${inspection._id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setInspectionDetails(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch inspection details');
      setLoading(false);
      console.error('Error fetching inspection details:', err);
    }
  };

  const handleDocumentDownload = async (documentId) => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/admin/document/${inspection._id}/${documentId}`, {
        responseType: 'blob'
      });
      
      const contentType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'document.pdf';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
        if (filenameMatch && filenameMatch.length === 2)
          filename = filenameMatch[1];
      }
      
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading document:', error);
      setError('Failed to download document: ' + error.message);
    }
  };
  const generatePublicDocumentUrl = (documentId) => {
    return `${config.apiBaseUrl}/admin/public/document/${inspection._id}/${documentId}`;
  };

  const getBase64Image = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/jpeg'));
      };
      img.onerror = reject;
      img.src = url;
    });
  };

  const generatePDF = async () => {
    setGeneratingPDF(true);
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    });
    
    let yOffset = 10;
  
    // Add company logo
    try {
      const logoData = '/finalLogo.png';
      pdf.addImage(logoData, 'PNG', 10, yOffset, 30, 15);
    } catch (error) {
      console.error('Error loading logo:', error);
    }
  
    // Add title
    pdf.setFontSize(24);
    pdf.setTextColor(44, 62, 80); // Dark blue color
    pdf.setFont('helvetica', 'bold');
    pdf.text('Inspection Report', pdf.internal.pageSize.width / 2, yOffset + 20, { align: 'center' });
    yOffset += 35;
  
    // Add decorative line
    pdf.setDrawColor(52, 152, 219); // Light blue color
    pdf.setLineWidth(0.5);
    pdf.line(10, yOffset, pdf.internal.pageSize.width - 10, yOffset);
    yOffset += 10;
  
    // Helper function for section headers
    const addSectionHeader = (text) => {
      pdf.setFontSize(16);
      pdf.setTextColor(52, 152, 219); // Light blue color
      pdf.setFont('helvetica', 'bold');
      pdf.text(text, 10, yOffset);
      yOffset += 8;
      pdf.setDrawColor(52, 152, 219);
      pdf.setLineWidth(0.2);
      pdf.line(10, yOffset, 60, yOffset);
      yOffset += 5;
    };
  
    // Helper function for content text
    const addContentText = (text, indent = 0) => {
      pdf.setFontSize(10);
      pdf.setTextColor(44, 62, 80); // Dark blue color
      pdf.setFont('helvetica', 'normal');
      pdf.text(text, 10 + indent, yOffset);
      yOffset += 5;
    };
  
    // Add device information
    addSectionHeader('Device Information');
    addContentText(`Serial Number: ${inspectionDetails.device.serialNumber}`);
    addContentText(`Location: ${inspectionDetails.device.location}`);
    addContentText(`Type: ${inspectionDetails.device.type}`);
    yOffset += 5;
  
    // Add inspection details
    addSectionHeader('Inspection Details');
    addContentText(`Inspector: ${inspectionDetails.inspector.name}`);
    addContentText(`Completed Date: ${new Date(inspectionDetails.completedDate).toLocaleString()}`);
    addContentText(`Result: ${inspectionDetails.result}`);
    yOffset += 5;
  
    // Add comments
    addSectionHeader('Comments');
    const splitComments = pdf.splitTextToSize(inspectionDetails.comments || 'No comments provided.', 180);
    pdf.text(splitComments, 10, yOffset);
    yOffset += splitComments.length * 5 + 5;
  
    // Add checklist
    addSectionHeader('Checklist');
    if (inspectionDetails.checklist && inspectionDetails.checklist.length > 0) {
      inspectionDetails.checklist.forEach((item) => {
        addContentText(`${item.checked ? '☑' : '☐'} ${item.name}`, 5);
      });
    } else {
      addContentText('No checklist items available.');
    }
    yOffset += 5;
  
    // Add photos
    addSectionHeader('Photos');
    if (inspectionDetails.photos && inspectionDetails.photos.length > 0) {
      for (let i = 0; i < inspectionDetails.photos.length; i++) {
        const photo = inspectionDetails.photos[i];
        try {
          const imgData = await getBase64Image(`${config.apiBaseUrl}/admin/image/${photo.filename}`);
          pdf.addImage(imgData, 'JPEG', 10, yOffset, 80, 60);
          yOffset += 65;
          if (yOffset > 250) {
            pdf.addPage();
            yOffset = 10;
          }
        } catch (error) {
          console.error('Error loading image:', error);
          addContentText(`Failed to load image: ${photo.filename}`);
        }
      }
    } else {
      addContentText('No photos available.');
    }
    yOffset += 5;
  
    // Add documents
    addSectionHeader('Documents');
    if (inspectionDetails.documents && inspectionDetails.documents.length > 0) {
      for (const doc of inspectionDetails.documents) {
        try {
          const publicDocUrl = generatePublicDocumentUrl(doc._id);
          pdf.setTextColor(52, 152, 219); // Light blue color for links
          pdf.textWithLink(`• ${doc.originalName}`, 10, yOffset, { url: publicDocUrl });
          yOffset += 5;
        } catch (error) {
          console.error(`Failed to add document ${doc.originalName}:`, error);
          addContentText(`• ${doc.originalName} (Failed to add)`);
        }
      }
    } else {
      addContentText('No documents available.');
    }
  
    // Add footer
    const pageCount = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8);
      pdf.setTextColor(128, 128, 128); // Gray color
      pdf.text(`Page ${i} of ${pageCount}`, pdf.internal.pageSize.width / 2, pdf.internal.pageSize.height - 10, { align: 'center' });
    }
  
    // Save the PDF
    try {
      pdf.save(`inspection_report_${inspection._id}.pdf`);
      console.log('PDF generated successfully with public document links');
    } catch (error) {
      console.error('Error saving PDF:', error);
      setError('Failed to generate PDF: ' + error.message);
    } finally {
      setGeneratingPDF(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!inspectionDetails) return null;
  return (
    <div className="inspection-report">
      <div className="report-header">
        <h2>Inspection Report</h2>
        <div className="report-actions">
          <button onClick={generatePDF} className="export-btn" disabled={generatingPDF}>
            <FontAwesomeIcon icon={faFilePdf} /> {generatingPDF ? 'Generating PDF...' : 'Export to PDF'}
          </button>
          <button onClick={onClose} className="close-btn"><FontAwesomeIcon icon={faTimes} /></button>
        </div>
      </div>
      <div className="report-content">
        <div className="report-details">
          <p><strong>Device Serial:</strong> {inspectionDetails.device.serialNumber}</p>
          <p><strong>Inspector:</strong> {inspectionDetails.inspector.name}</p>
          <p><strong>Completed Date:</strong> {new Date(inspectionDetails.completedDate).toLocaleString()}</p>
          <p><strong>Result:</strong> {inspectionDetails.result}</p>
          <p><strong>Comments:</strong> {inspectionDetails.comments}</p>
        </div>
        <div className="checklist">
          <h4>Checklist</h4>
          <ul>
            {inspectionDetails.checklist.map((item, index) => (
              <li key={index} className={item.checked ? 'checked' : 'unchecked'}>
                {item.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="photos">
          <h4>Photos</h4>
          <div className="photo-gallery">
            {inspectionDetails.photos.map((photo, index) => (
              <img key={index} src={`${config.apiBaseUrl}/admin/image/${photo.filename}`} alt={`Inspection photo ${index + 1}`} />
            ))}
          </div>
        </div>
        <div className="documents">
          <h4>Documents</h4>
          <ul>
            {inspectionDetails.documents.map((doc) => (
              <li key={doc._id}>
                <a href="#" onClick={(e) => { e.preventDefault(); handleDocumentDownload(doc._id); }} className="document-download-link">
                  <FontAwesomeIcon icon={faFileDownload} /> {doc.originalName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;