import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileDownload, faCheckCircle, faTimesCircle, faExclamationCircle, faSpinner, faExclamationTriangle, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import config from '../../config';
import './InspectionReport.scss';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './InspectionReport.css';

const InspectionReport = ({ inspectionId, onClose }) => {
    const [inspection, setInspection] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imageLoadErrors, setImageLoadErrors] = useState({});
    const [pdfUrl, setPdfUrl] = useState(null);
    const [generatingPDF, setGeneratingPDF] = useState(false);

    const fetchInspectionDetails = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/manager/inspection-details/${inspectionId}`);
        setInspection(response.data);
        setLoading(false);
        console.log('Fetched inspection:', response.data);
      } catch (err) {
        setError('Failed to fetch inspection details: ' + (err.response?.data?.message || err.message));
        setLoading(false);
        console.error('Error fetching inspection details:', err);
      }
    };

    useEffect(() => {
      fetchInspectionDetails();
    }, [inspectionId]);

    const getStatusIcon = (status) => {
      switch (status) {
        case 'Approved':
          return <FontAwesomeIcon icon={faCheckCircle} className="status-icon approved" />;
        case 'Rejected':
          return <FontAwesomeIcon icon={faTimesCircle} className="status-icon rejected" />;
        case 'Maintenance Needed':
          return <FontAwesomeIcon icon={faExclamationCircle} className="status-icon maintenance" />;
        default:
          return null;
      }
    };

    const handleDocumentDownload = async (documentId) => {
      try {
        console.log('Attempting to download document:', documentId);
        const response = await axios.get(`${config.apiBaseUrl}/manager/document/${inspectionId}/${documentId}`, {
          responseType: 'blob'
        });
        
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        
        // Check if Content-Disposition header exists and extract filename
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'document.pdf';
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
          if (filenameMatch && filenameMatch.length === 2)
            filename = filenameMatch[1];
        }
        
        // Ensure the filename ends with .pdf
        if (!filename.toLowerCase().endsWith('.pdf')) {
          filename += '.pdf';
        }
        
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        console.log('Document download successful');
      } catch (error) {
        console.error('Error downloading document:', error);
        setError('Failed to download document: ' + (error.response?.data?.message || error.message));
      }
    };

    const handleImageError = (index, filename) => {
      setImageLoadErrors(prev => ({ ...prev, [index]: true }));
      console.error(`Failed to load image at index ${index}, filename: ${filename}`);
      console.error(`Image URL: ${config.apiBaseUrl}/manager/image/${filename}`);
    };
    const fetchDocument = async (documentId) => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/manager/document/${inspectionId}/${documentId}`, {
          responseType: 'arraybuffer',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        return new Uint8Array(response.data);
      } catch (error) {
        console.error('Error fetching document:', error);
        throw error;
      }
    };
    const retryImageLoad = (index) => {
      setImageLoadErrors(prev => ({ ...prev, [index]: false }));
      console.log(`Retrying to load image at index ${index}`);
    };
    const generatePublicDocumentUrl = (documentId) => {
      // Generate a public URL for the document
      // This URL should not require authentication
      return `${config.apiBaseUrl}/manager/public/document/${inspectionId}/${documentId}`;
    };
    const generatePDF = async () => {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });
      
      let yOffset = 10;
    
      // Add company logo
      try {
        const logoData ='/finalLogo.png';
        pdf.addImage(logoData, 'PNG', 10, yOffset, 30, 15);
      } catch (error) {
        console.error('Error loading logo:', error);
      }
    
      // Add title
      pdf.setFontSize(24);
      pdf.setTextColor(44, 62, 80); // Dark blue color
      pdf.setFont('helvetica', 'bold');
      pdf.text('Inspection Report', pdf.internal.pageSize.width / 2, yOffset + 20, { align: 'center' });
      yOffset += 35;
    
      // Add decorative line
      pdf.setDrawColor(52, 152, 219); // Light blue color
      pdf.setLineWidth(0.5);
      pdf.line(10, yOffset, pdf.internal.pageSize.width - 10, yOffset);
      yOffset += 10;
    
      // Helper function for section headers
      const addSectionHeader = (text) => {
        pdf.setFontSize(16);
        pdf.setTextColor(52, 152, 219); // Light blue color
        pdf.setFont('helvetica', 'bold');
        pdf.text(text, 10, yOffset);
        yOffset += 8;
        pdf.setDrawColor(52, 152, 219);
        pdf.setLineWidth(0.2);
        pdf.line(10, yOffset, 60, yOffset);
        yOffset += 5;
      };
    
      // Helper function for content text
      const addContentText = (text, indent = 0) => {
        pdf.setFontSize(10);
        pdf.setTextColor(44, 62, 80); // Dark blue color
        pdf.setFont('helvetica', 'normal');
        pdf.text(text, 10 + indent, yOffset);
        yOffset += 5;
      };
    
      // Add device information
      addSectionHeader('Device Information');
      addContentText(`Serial Number: ${inspection.device.serialNumber}`);
      addContentText(`Location: ${inspection.device.location}`);
      addContentText(`Type: ${inspection.device.type}`);
      yOffset += 5;
    
      // Add inspection details
      addSectionHeader('Inspection Details');
      addContentText(`Inspector: ${inspection.inspector.name}`);
      addContentText(`Scheduled Date: ${new Date(inspection.scheduledDate).toLocaleString()}`);
      addContentText(`Completed Date: ${inspection.completedDate ? new Date(inspection.completedDate).toLocaleString() : 'N/A'}`);
      addContentText(`Status: ${inspection.status}`);
      addContentText(`Result: ${inspection.result}`);
      yOffset += 5;
    
      // Add comments
      addSectionHeader('Comments');
      const splitComments = pdf.splitTextToSize(inspection.comments || 'No comments provided.', 180);
      pdf.text(splitComments, 10, yOffset);
      yOffset += splitComments.length * 5 + 5;
    
      // Add checklist
      addSectionHeader('Checklist');
      if (inspection.checklist && inspection.checklist.length > 0) {
        inspection.checklist.forEach((item) => {
          addContentText(`${item.checked ? '☑' : '☐'} ${item.name}`, 5);
        });
      } else {
        addContentText('No checklist items available.');
      }
      yOffset += 5;
    
      // Add photos
      addSectionHeader('Photos');
      if (inspection.photos && inspection.photos.length > 0) {
        for (let i = 0; i < inspection.photos.length; i++) {
          const photo = inspection.photos[i];
          try {
            const imgData = await getBase64Image(`${config.apiBaseUrl}/manager/image/${photo.filename}`);
            pdf.addImage(imgData, 'JPEG', 10, yOffset, 80, 60);
            yOffset += 65;
            if (yOffset > 250) {
              pdf.addPage();
              yOffset = 10;
            }
          } catch (error) {
            console.error('Error loading image:', error);
            addContentText(`Failed to load image: ${photo.filename}`);
          }
        }
      } else {
        addContentText('No photos available.');
      }
      yOffset += 5;
    
      // Add documents
      addSectionHeader('Documents');
      if (inspection.documents && inspection.documents.length > 0) {
        for (const doc of inspection.documents) {
          try {
            const publicDocUrl = generatePublicDocumentUrl(doc._id);
            pdf.setTextColor(52, 152, 219); // Light blue color for links
            pdf.textWithLink(`• ${doc.originalName}`, 10, yOffset, { url: publicDocUrl });
            yOffset += 5;
            console.log(`Document ${doc.originalName} added to PDF with public clickable link`);
          } catch (error) {
            console.error(`Failed to add document ${doc.originalName}:`, error);
            addContentText(`• ${doc.originalName} (Failed to add)`);
          }
        }
      } else {
        addContentText('No documents available.');
      }
    
      // Add footer
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        pdf.setTextColor(128, 128, 128); // Gray color
        pdf.text(`Page ${i} of ${pageCount}`, pdf.internal.pageSize.width / 2, pdf.internal.pageSize.height - 10, { align: 'center' });
      }
    
      // Save the PDF
      try {
        pdf.save('inspection_report.pdf');
        console.log('PDF generated successfully with public document links');
      } catch (error) {
        console.error('Error saving PDF:', error);
        setError('Failed to generate PDF: ' + error.message);
      } finally {
        setGeneratingPDF(false);
      }
    };
    const getBase64Image = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL('image/jpeg'));
        };
        img.onerror = reject;
        img.src = url;
      });
    };

    if (loading) {
      return (
        <div className="inspection-report loading">
          <FontAwesomeIcon icon={faSpinner} spin /> Loading inspection report...
        </div>
      );
    }

    if (error) {
      return (
        <div className="inspection-report error">
          <p>{error}</p>
          <button onClick={onClose}>Close</button>
        </div>
      );
    }

    if (!inspection) {
      return null;
    }

    return (
      <div className="inspection-report">
        <div className="report-header">
          <h2>Inspection Report</h2>
          <div className="report-actions">
            <button onClick={generatePDF} className="export-btn">
              <FontAwesomeIcon icon={faFilePdf} /> Export to PDF
            </button>
            <button onClick={onClose} className="close-btn">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
        <div id="inspection-report-content" className="report-content">
          <div className="report-section device-info">
            <h3>Device Information</h3>
            <p><strong>Serial Number:</strong> {inspection.device.serialNumber}</p>
            <p><strong>Location:</strong> {inspection.device.location}</p>
            <p><strong>Type:</strong> {inspection.device.type}</p>
          </div>

          <div className="report-section inspection-details">
            <h3>Inspection Details</h3>
            <p><strong>Inspector:</strong> {inspection.inspector.name}</p>
            <p><strong>Scheduled Date:</strong> {new Date(inspection.scheduledDate).toLocaleString()}</p>
            <p><strong>Completed Date:</strong> {inspection.completedDate ? new Date(inspection.completedDate).toLocaleString() : 'N/A'}</p>
            <p><strong>Status:</strong> {inspection.status}</p>
            <p className="result"><strong>Result:</strong> {getStatusIcon(inspection.result)} {inspection.result}</p>
          </div>

          <div className="report-section comments">
            <h3>Comments</h3>
            <p>{inspection.comments || 'No comments provided.'}</p>
          </div>

          <div className="report-section checklist">
            <h3>Checklist</h3>
            {inspection.checklist && inspection.checklist.length > 0 ? (
              <ul>
                {inspection.checklist.map((item) => (
                  <li key={item.id} className={item.checked ? 'checked' : 'unchecked'}>
                    <FontAwesomeIcon icon={item.checked ? faCheckCircle : faTimesCircle} />
                    {item.name}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No checklist items available.</p>
            )}
          </div>

          <div className="report-section photos">
            <h3>Photos</h3>
            <div className="photo-gallery">
              {inspection.photos && inspection.photos.length > 0 ? (
                inspection.photos.map((photo, index) => (
                  <div key={photo._id} className="photo-container">
                    {!photo.filename ? (
                      <div className="image-error">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <p>Image filename not available</p>
                      </div>
                    ) : imageLoadErrors[index] ? (
                      <div className="image-error">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <p>Failed to load image</p>
                        <button onClick={() => retryImageLoad(index)}>Retry</button>
                      </div>
                    ) : (
                      <img
                        src={`${config.apiBaseUrl}/manager/image/${photo.filename}`}
                        alt={`Inspection photo ${index + 1}`}
                        onError={() => handleImageError(index, photo.filename)}
                      />
                    )}
                  </div>
                ))
              ) : (
                <p>No photos available.</p>
              )}
            </div>
          </div>

          <div className="report-section documents">
            <h3>Documents</h3>
            {inspection.documents && inspection.documents.length > 0 ? (
              <ul>
                {inspection.documents.map((doc) => (
                  <li key={doc._id}>
                    <a href="#" onClick={(e) => { e.preventDefault(); handleDocumentDownload(doc._id); }} className="document-download-link">
                      <FontAwesomeIcon icon={faFileDownload} /> {doc.originalName}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No documents available.</p>
            )}
          </div>
        </div>
      </div>
    );
};

export default InspectionReport;